<template>
  <div class="index-content">

  </div>
</template>

<script>
import axios from "axios";
import * as constans from "@/config/constans";

export default {
  data() {
    return {
      
    };
  },
  created(){
    window.location = constans.officialWebsiteUrl
  },
  methods: {
    getValue() {
      this.loginshow = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
  @import "./index.scss";
</style>